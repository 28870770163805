
import { Product } from "@/modules/product/api/productShow";
import Vue from "vue";
import ProductDetailCard from "@/modules/product/components/ProductDetailCard.vue";
import ProductDetailCardTable from "@/modules/product/components/ProductDetailCardTable.vue";

export default Vue.extend({
  name: "StockDetails",
  components: { ProductDetailCardTable, ProductDetailCard },
  props: {
    product: {
      type: Object as unknown as () => Product | null,
    },
  },
  computed: {
    stockDetails() {
      return [
        { key: "minStock" },
        { key: "totalStock" },
        { key: "inTransitStock" },
        { key: "onHoldStock" },
        { key: "availableStock" },
        { key: "inboundStock" },
      ];
    },
  },
});
