<template>
  <v-card class="flex-grow-1">
    <v-card-title class="d-flex justify-space-between">
      <h3 class="mb-2 text-subtitle-1 font-weight-light">
        <span class="d-inline-flex mr-3">
          <v-icon color="accent" class="ma-1">$dots</v-icon>
        </span>
        {{ title }}
      </h3>
      <v-icon color="accent" class="mr-3 expand-icon">$dropdown</v-icon>
    </v-card-title>

    <v-card-text>
      <slot name="default"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProductDetailCard",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
