
import { Product } from "@/modules/product/api/productShow";
import Vue from "vue";
import ProductDetailCard from "@/modules/product/components/ProductDetailCard.vue";
import ProductDetailCardContent from "@/modules/product/components/ProductDetailCardContent.vue";
import { getTranslatedEnumValue } from "@/application/util/enum";
import { Unit } from "@/modules/product/enum/Unit";

export default Vue.extend({
  name: "ProductDetails",
  components: { ProductDetailCardContent, ProductDetailCard },
  props: {
    product: {
      type: Object as unknown as () => Product | null,
    },
  },
  computed: {
    Unit() {
      return Unit;
    },
    firstColumn() {
      return [
        { key: "tradeUnit" },
        { key: "hu" },
        { key: "sku" },
        { key: "dimensions" },
        { key: "weight" },
        { key: "hsCode" },
      ];
    },
    secondColumn() {
      return [
        { key: "countryOrigin" },
        { key: "customsPrice" },
        { key: "serialNumber" },
        { key: "outManagement" },
      ];
    },
  },
  methods: {
    getTranslatedEnumValue,
  },
});
