import { get } from "@/application/api";
import { AxiosResponse } from "axios";

export interface Product {
  clientId: number;
  inboundStock: number;
  outManagement: string;
  hu: number;
  productName: string;
  serialNumber: string;
  articleNumber: string;
  ean: string;
  deleted: boolean;
  isActive: boolean;
  onHoldStock: number;
  sku: number;
  itemnums: string;
  availableStock: number;
  assembledItem: boolean;
  countryOrigin: string;
  updatedAt: string;
  tradeUnit: string;
  totalStock: number;
  inTransitStock: number;
  weight: number;
  dimensions: string;
  type: string;
  status: string;
  revenueCode: string;
  hsCode: string;
  createdAt: string;
  id: number;
  customsPrice: string;
  minStock: number;
}

export async function ProductShow({
  clientId,
  productId,
}: {
  clientId: number;
  productId: number;
}): Promise<
  AxiosResponse<{
    data: Product;
    validationErrors: { message: string; errors: Record<string, string[]> };
  }>
> {
  return get(`client/${clientId}/product/${productId}`);
}
