
import Vue from "vue";
import { translateField } from "@/application/util/translation";
import { Product } from "@/modules/product/api/productShow";

interface Value {
  key: string;
}

export default Vue.extend({
  name: "ProductDetailCardTable",
  props: {
    languagePrefix: {
      type: String,
      required: true,
    },
    values: {
      type: Array as unknown as () => Value[],
      required: true,
    },
    product: {
      type: Object as unknown as () => Product,
      required: true,
    },
  },
  methods: {
    translateField,
  },
});
