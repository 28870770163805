
import { Product } from "@/modules/product/api/productShow";
import Vue from "vue";
import ProductDetailCard from "@/modules/product/components/ProductDetailCard.vue";
import ProductDetailCardContent from "@/modules/product/components/ProductDetailCardContent.vue";
import dayjs from "@/plugins/dayjs";

export default Vue.extend({
  name: "ProductInformation",
  components: { ProductDetailCardContent, ProductDetailCard },
  props: {
    product: {
      type: Object as unknown as () => Product | null,
    },
  },
  computed: {
    productInformation() {
      return [
        { key: "articleNumber" },
        { key: "productName" },
        { key: "ean" },
        { key: "assembledItem" },
        { key: "isActive" },
        { key: "createdAt" },
      ];
    },
  },
  methods: {
    formatDate(date: string) {
      return dayjs(date).format("LL");
    },
  },
});
