
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import { Product, ProductShow } from "@/modules/product/api/productShow";
import eventBus from "@/application/eventBus";
import ProductInformation from "@/modules/product/components/ProductInformation.vue";
import ProductDetails from "@/modules/product/components/ProductDetails.vue";
import StockDetails from "@/modules/product/components/StockDetails.vue";

interface ComponentData {
  value: Product | null;
  isLoading: boolean;
  displayDetails: boolean;
  products?: ProductNext[];
}

interface ProductNext {
  id: number;
  articleNumber: string;
}

export default Vue.extend({
  name: "SalesOrderDetail",
  components: {
    StockDetails,
    ProductDetails,
    ProductInformation,
    RequiredClientDialog,
  },
  data: (): ComponentData => ({
    isLoading: true,
    value: null,
    displayDetails: true,
    products: [],
  }),
  watch: {
    "$route.params": {
      immediate: true,
      deep: true,
      async handler(value) {
        const { productId, products } = value;
        await this.getProduct(Number(productId));

        if (products) this.products = JSON.parse(products);
      },
    },
  },
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    clientId(): number {
      return this.client?.id;
    },
    currentProductIndex(): number {
      return (
        this.products?.findIndex((order) => order?.id === this.value?.id) ?? -1
      );
    },
    previousProduct(): ProductNext | undefined {
      return this.products?.[this.currentProductIndex - 1];
    },
    nextProduct(): ProductNext | undefined {
      return this.products?.[this.currentProductIndex + 1];
    },
  },
  methods: {
    ...mapMutations("error", { addValidationErrorToStore: "add" }),
    toggleDisplayProdictDetails() {
      this.displayDetails = !this.displayDetails;
    },
    async getProduct(id: number): Promise<void> {
      try {
        this.isLoading = true;
        const response = await ProductShow({
          productId: id,
          clientId: this.clientId,
        });
        if (response.data.validationErrors) {
          setTimeout(() => {
            Object.keys(response.data.validationErrors.errors).forEach(
              (key) => {
                this.addValidationErrorToStore({
                  key,
                  message: response.data.validationErrors.errors[key][0],
                });
              }
            );
            (this.$refs.form as any).$refs.form.validate();
          }, 500);
        }
        this.value = response.data.data;
      } catch (error: any) {
        if (error.response?.status === 404) {
          this.$router.push({ name: "notFound" });
          return;
        }
        eventBus.$emit("snackbar", {
          text: this.$t("global.error"),
          color: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
