var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('RequiredClientDialog'),_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"to":"/product","depressed":"","icon":""}},[_c('v-icon',{attrs:{"icon":"mdi-arrow-left"}},[_vm._v("$prev")])],1)],1),_c('v-row',{staticClass:"px-3",on:{"click":_vm.toggleDisplayProdictDetails}},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"8"}},[_c('ProductInformation',{attrs:{"product":_vm.value}})],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"8"}},[_c('StockDetails',{attrs:{"product":_vm.value}})],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"8"}},[_c('ProductDetails',{attrs:{"product":_vm.value}})],1)],1),(_vm.products && (_vm.previousProduct || _vm.nextProduct))?_c('div',{staticClass:"justify-end d-flex pa-4"},[_c('v-tooltip',{attrs:{"disabled":!_vm.previousProduct,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.previousProduct,"to":_vm.previousProduct && {
                  name: 'product.show',
                  params: {
                    productId: _vm.previousProduct?.id,
                    products: JSON.stringify(_vm.products),
                  },
                }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"teal--text"},[_vm._v("$prev")])],1)]}}],null,false,1495987300)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("product.titles.product"))+" "+_vm._s(_vm.previousProduct?.articleNumber)+" ")])]),_c('v-tooltip',{attrs:{"disabled":!_vm.nextProduct,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.nextProduct,"to":_vm.nextProduct && {
                  name: 'product.show',
                  params: {
                    productId: _vm.nextProduct?.id,
                    products: JSON.stringify(_vm.products),
                  },
                }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"teal--text"},[_vm._v("$next")])],1)]}}],null,false,3872450340)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("product.titles.product"))+" "+_vm._s(_vm.nextProduct?.articleNumber)+" ")])])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }